import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NormalTabs from 'ThreeTerminal_mobile/tabs/normalTabs';
import InformationFlow from 'ThreeTerminal_mobile/informationFlow/informationFlow';
import ViewMoreLink from 'ThreeTerminal_mobile/tabs/viewMoreLink';
import styles from './index.css';

const Channels = ({ content }) => {
    const { title, mobileUrl, list, id } = content;

    return (
        <Chip id={id} type="recommend" title={title} groupName="正文">
            <div className={styles.wrap}>
                <NormalTabs hasSticky={true} content={{ title, url: mobileUrl }} />
                <InformationFlow content={list} isLazyImage={true} isDisplayLoadingMore={false} />
                <ViewMoreLink content={{ link: mobileUrl, title: '更多' }} />
            </div>
        </Chip>
    );
};

Channels.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Channels);
