import React from 'react';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PageNav from './component/PageNav';
import Logo from './component/Logo';
import Slide from './component/Slide';
import Channels from './component/Channels';
import Footer from './component/Footer';
import BackTopBottom from 'ThreeTerminal_mobile/backTopBottom/backTopBottom';

const navToContent = {
    info: 'zixunContent',
    finance: 'caijingContent',
    subject: 'zhuantiContent',
    humanities: 'renwenContent',
    history: 'lishiContent',
    interview: 'fangtanContent',
    'cross-strait': 'lianganContent',
    ghm: 'yuegangaoContent',
    special: 'tebiejiemuContent',
};

const Layout = ({ content }) => {
    const logoData = {
        logo: content.logo,
    };

    const navData = {
        nav: content.subNav,
    };

    const slideData = {
        slides: content.slideData,
    };

    const footerData = {
        footerList: content.footerInfo,
    };

    const channelsData =
        content.subNav && Array.isArray(content.subNav)
            ? content.subNav.map(item => {
                  const { title, mobileUrl, param } = item;

                  const contentData =
                      navToContent[param] && content[navToContent[param]] ? content[navToContent[param]] : {};
                  const { list = [], id = '' } = contentData;

                  return { title, mobileUrl, list, id };
              })
            : [];

    return (
        <div className={styles.layout}>
            <Chip id="200164" type="struct" title="Logo" groupName="头部" content={logoData}>
                <Logo />
            </Chip>
            <Chip id="230203" type="struct" title="顶部导航" groupName="头部" content={navData}>
                <PageNav />
            </Chip>
            <Chip id="195946" type="recommend" title="焦点图" groupName="正文" content={slideData}>
                <Slide />
            </Chip>
            <Channels content={channelsData} />
            <Footer content={footerData} />
            <BackTopBottom />
            <ChipEdit transform={transform} />
        </div>
    );
};

Layout.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Layout);
