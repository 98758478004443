import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';

const Logo = ({ content }) => {
    const { src, title } = content.logo;

    return (
        <div className={styles.wrap}>
            <div className={styles.logo}>
                <img src={src} alt={title} title={title} />
            </div>
        </div>
    );
};

Logo.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Logo);
