import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import IndexSlides from 'ThreeTerminal_mobile/slides/indexSlides';
import { formatImage } from '@ifeng-fe/three_terminal/es/base/publicMethod/formatImage';

const Slide = ({ content }) => {
    const { slides } = content;
    const slideContent =
        slides && Array.isArray(slides)
            ? slides.map(item => {
                  const { title, url, thumbnail } = item;
                  const src = formatImage(thumbnail, 690, 350);

                  return { title, url, src };
              })
            : [];

    return (
        <div className={styles.wrap}>
            {slideContent.length ? <IndexSlides content={slideContent} /> : <div className={styles.placeholder} />}
        </div>
    );
};

Slide.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Slide);
