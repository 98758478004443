import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import List from './List';

const Channels = ({ content }) => {
    return <div className={styles.wrap}>{content.map(item => <List content={item} key={item.id} />)}</div>;
};

Channels.propTypes = {
    content: PropTypes.array,
};

export default errorBoundary(Channels);
