import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { HomeFooter, Copyright } from 'ThreeTerminal_mobile/copyright';

const Footer = ({ content }) => {
    const { footerList } = content;
    const homeFooter =
        footerList && Array.isArray(footerList)
            ? footerList.map(item => {
                  let clickConfig = undefined;

                  if (item.title === '客户端') {
                      clickConfig = {
                          behavior: 'action',
                          type: 'openapp_info',
                      };
                  }

                  return {
                      title: item.title,
                      url: item.url,
                      clickConfig,
                  };
              })
            : [];

    return (
        <>
            {homeFooter.length ? (
                <Chip id="50168" type="struct" title="页底数据" groupName="content" content={homeFooter}>
                    <HomeFooter />
                </Chip>
            ) : null}
            <Copyright />
        </>
    );
};

Footer.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Footer);
