import React from 'react';
import ReactDOM from 'react-dom';
import '../reset.css';
import Layout from './layout/';
import { formatImage } from '@ifeng-fe/public_method';

/* eslint-disable */
const handleVideoSlideData = data => {
    try {
        return data.slice(0, 5).map(item => ({
            thumbnail: item.thumbnail && formatImage(item.thumbnail, 720, 416),
            url: item.url,
            title: item.title,
        }));
    } catch (error) {
        console.error(error);
    }

    return data;
};

const handleSectionContentData = (data, id) => {
    const formatData =
        data &&
        data.map(item => {
            const {
                title,
                type,
                thumbnail,
                thumbnails,
                url,
                id,
                base62Id,
                guid,
                newsTime,
                commentUrl,
                duration,
                position,
                selectedPoolTags,
                source,
            } = item;
            const newStyle = 'titleimg';
            const articleTag = '视频';
            const articleTagColor = 10;
            const hasVideoIcon = 1;
            const picNum = 0;

            return {
                title,
                type,
                thumbnail,
                thumbnails,
                url,
                id,
                base62Id,
                guid,
                newsTime,
                commentUrl,
                duration,
                position,
                selectedPoolTags,
                source,
                newStyle,
                articleTag,
                articleTagColor,
                hasVideoIcon,
                picNum,
            };
        });

    return {
        list: formatData ? formatData : [],
        id,
    };
};

try {
    allData.slideData = allData.slideData && handleVideoSlideData(allData.slideData);

    allData.zixunContent = allData.zixunContent && handleSectionContentData(allData.zixunContent, '195948');
    allData.caijingContent = allData.caijingContent && handleSectionContentData(allData.caijingContent, '195949');
    allData.zhuantiContent = allData.zhuantiContent && handleSectionContentData(allData.zhuantiContent, '195950');
    allData.renwenContent = allData.renwenContent && handleSectionContentData(allData.renwenContent, '225880');
    allData.lishiContent = allData.lishiContent && handleSectionContentData(allData.lishiContent, '195951');
    allData.fangtanContent = allData.fangtanContent && handleSectionContentData(allData.fangtanContent, '225881');
    allData.lianganContent = allData.lianganContent && handleSectionContentData(allData.lianganContent, '256035');
    allData.yuegangaoContent = allData.yuegangaoContent && handleSectionContentData(allData.yuegangaoContent, '225882');
    allData.tebiejiemuContent =
        allData.tebiejiemuContent && handleSectionContentData(allData.tebiejiemuContent, '225883');
} catch (e) {
    console.error(e);
}

ReactDOM.render(<Layout content={allData} />, document.getElementById('root'));
/* eslint-enable */
