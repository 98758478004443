import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Nav from 'ThreeTerminal_mobile/nav/nav';

const PageNav = ({ content }) => {
    const navs = content.nav
        ? content.nav.map(item => {
              const { title, mobileUrl: url } = item;

              return { title, url };
          })
        : [];

    return <Nav content={navs} colNum={5} showMoreArrow={false} />;
};

PageNav.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(PageNav);
